import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import orange from '@material-ui/core/colors/orange';
import { withStyles } from "@material-ui/core/styles/";
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';

const styles = theme => ({
    palette: {
        primary: orange,
    },
    indicator: {
        backgroundColor: "orange"
    },
    default_tabStyle: {
        color: 'grey',
    },
    active_tabStyle: {
        fontWeight: 'bold',
        color: 'black',
    }
});

function ClaimsPage(props) {
    const token = props.auth.authDetails.data.access_token;
    const chhID = props.auth.currentClinicLocation.id;
    const isProductionEnv = process.env.REACT_APP_ENV === 'production';
    const appSmithAppEnv = isProductionEnv ? 'prod' : 'dev';

    useEffect(() => {
        // Add state parameter to persist these values across page navigation
        const externalUrl = `https://appsmith.clinikk.com/app/claims-app/dashboard-67da61c5857f5654d02115d1?token=${token}&chhID=${chhID}&appEnv=${appSmithAppEnv}&state=persist`;

        window.open(externalUrl, '_blank');
        window.history.back();
    }, [token, chhID, appSmithAppEnv]);

    return (
        <div className='main' style={{
            height: "100vh",
            minWidth: '80vw',
            backgroundColor: '#f4f4f4',
        }}>
            <TopBar />
            <div className='main' style={{
                height: "100vh",
                width: '100vw',
                backgroundColor: '#f4f4f4',
                display: 'flex'
            }}>
                <SideMenuBar />
                <div style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1.2rem'
                }}>
                    Redirecting to Claims Dashboard...
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error };
};

export default connect(mapStateToProps)(withStyles(styles)(ClaimsPage));