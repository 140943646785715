import React from 'react';
import css from './style.module.scss';

import { connect } from 'react-redux';

import { getAllClinics, logOut, resetOldFamilyDetails } from '../../../actions';

import { authActions } from '../../../actions';


import { ReactComponent as BlueDownArrow } from '../../../assets/icons/blue-down-arrow.svg';
import { ReactComponent as Location } from '../../../assets/icons/location.svg';
import { ReactComponent as ProfileRound } from '../../../assets/icons/profile_round.svg';
import { ReactComponent as ClinikkLogo } from '../../../assets/logos/clinikk_logo.svg';

import ToggleSwitch from '../../../widgets/toggleSwitch';

class TopBar extends React.Component {
  state = { anchorEl: null, available: false }

  componentDidMount() {
    this.props.getClinicList();
    const { getAvailability, auth } = this.props;
    let partnerId;
    if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
      partnerId = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user ? auth.authDetails.data.user.id : '';
    }
    getAvailability(partnerId);
    document.addEventListener('visibilitychange', () => {
      if (auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user) {
        getAvailability(partnerId);
      }
    });
  }

  changeAvailability = () => {
    const { setAvailability, auth } = this.props;
    const partnerId = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user ? auth.authDetails.data.user.id : '';
    setAvailability(partnerId);
  }

  render() {
    const { auth } = this.props;
    let availability = auth && auth.authDetails && auth.authDetails.data && auth.authDetails.data.user && auth.authDetails.data.user.metadata ? auth.authDetails.data.user.metadata.availability : false;
    return (
      <div>
        <div className={css.topbar_container}>
          <div className={css.topbar_container_left}>
            <ClinikkLogo className={css.topbar_container_left_icon} />
            <p>OPERATIONS</p>
          </div>

          <div className={css.topbar_container_right}>
            <div className={css.topbar_container_right_location}>
              <Location />
              <p>{this.props.auth && this.props.auth.currentClinicLocation ? this.props.auth.currentClinicLocation.name : 'loading...'}</p>
            </div>
            <div className={css.topbar_container_right_availability}>
              <p>{availability ? 'Available' : 'Availability'}</p>
              <ToggleSwitch onChange={this.changeAvailability} active={availability} />
            </div>
            <div className={css.topbar_container_right_profile}>
              <ProfileRound />
              <p>{this.props.auth && this.props.auth.authDetails && this.props.auth.authDetails.data && this.props.auth.authDetails.data.user ? this.props.auth.authDetails.data.user.last_name ? this.props.auth.authDetails.data.user.first_name + ' ' + this.props.auth.authDetails.data.user.last_name : this.props.auth.authDetails.data.user.first_name : 'loading...'}</p>
              <BlueDownArrow />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAvailability: (partnerId) => {
    dispatch(authActions.setAvailabilityEvent(partnerId));
  },
  getAvailability: (partnerId) => {
    dispatch(authActions.getAvailabilityEvent(partnerId));
  },
  logOut: () => {
    dispatch(logOut());
  },
  resetError: () => {
    dispatch(resetOldFamilyDetails());
  },
  setKeyWordExpiration: () => {
    dispatch(getAllClinics());
  },
  getClinicList: () => {
    dispatch(getAllClinics());
  }
});

const mapStateToProps = (state) => {
  return { auth: state.auth, familyDetails: state.familyDetails, consultationDetails: state.consultationDetails, error: state.error };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);