import React from 'react';
import { connect } from 'react-redux';

import orange from '@material-ui/core/colors/orange';
import { withStyles } from "@material-ui/core/styles/";
import SideMenuBar from '../components/Home/sideMenuBar/SideMenuBar';
import TopBar from '../components/Home/topBar/TopBar';

const styles = theme => ({
	palette: {
		primary: orange,
	},
	indicator: {
		backgroundColor: "orange"
	},
	default_tabStyle: {
		color: 'grey',
	},
	active_tabStyle: {
		fontWeight: 'bold',
		color: 'black',
	}
});

function VisitsPage(props) {
	const token = props.auth.authDetails.data.access_token;
	const chhID = props.auth.currentClinicLocation.id;
	const isProductionEnv = process.env.REACT_APP_ENV === 'production';
	const appSmithAppEnv = isProductionEnv ? 'prod' : 'dev';
	return (
		<div className='main' style={{
			height: "100vh",
			minWidth: '80vw',
			backgroundColor: '#f4f4f4',
		}}>
			<TopBar />
			<div className='main' style={{
				height: "100vh",
				width: '100vw',
				backgroundColor: '#f4f4f4',
				display: 'flex'
			}}>

				<SideMenuBar />
				<div style={{
					flexGrow: 1,
					height: '100vh',
					margin: 0,
					padding: 0,
					overflow: 'hidden'
				}}>
					<iframe
						src={`https://appsmith.clinikk.com/app/operations/visits-6694c41883fe634d2aa0a593?token=${token}&chhID=${chhID}&appEnv=${appSmithAppEnv}`}
						title="Visits"
						style={{
							width: '100%',
							height: '100%',
							border: 'none'
						}}
					>
					</iframe>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return { auth: state.auth, error: state.error };
};

export default connect(mapStateToProps)(withStyles(styles)(VisitsPage));