import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import "./App.css";

import { PrivateRoute } from "../common/router";
import history from "../history";
import GettingDetails from "../Pages/GettingDetails";
import LoginPage from "../Pages/LoginPage";
import VerifyOtp from "../Pages/VerifyOtp";

import AddNewCustomerPage from "../Pages/AddNewCustomerPage";
import BuyProductsPage from "../Pages/BuyProductsPage";
import CustomerDetailsTaskPage from "../Pages/CustomerDetailsTaskPage";
import HomePage from "../Pages/HomePage";
import LoadingHome from "../Pages/LoadingHome";
import SelectClinicLocation from "../Pages/SelectClinicLocationPage";
import TodaysConsultationsPage from "../Pages/TodaysConsultationsPage";

import AdminTaskPage from "../Pages/AdminTaskPage";
import CareDocUpload from "../Pages/CareDocUpload";
import SalesPage from "../Pages/SalesPage";
// import TicketingPage from "../Pages/TicketingPage";

import firebaseActions from "../config/firebase";
import ClaimsPage from "../Pages/ClaimsPage";
import CreateDoctorPage from "../Pages/CreateDoctorPage";
import CustomerLensPage from "../Pages/CustomerLensPage";
import Membership from "../Pages/Membership";
import UtilitiesPage from "../Pages/UtilitiesPage";
import VisitsPage from "../Pages/VisitsPage";

const App = (props) => {
	// Fetch the quick symptoms to be populated
	// in the consultation booking form
	useEffect(() => {
		firebaseActions.fetchQuickSymptomsFromFirebase();
	}, []);
	return (
		<Router history={history}>
			<Switch>
				<Route path="/" exact component={LoginPage} />
				<Route exact path="/health">
					<h3>Hey There!!! The App is Healthy</h3>
				</Route>
				<Route path="/verifyOtp" component={VerifyOtp} />
				<PrivateRoute
					path="/select-location"
					Component={SelectClinicLocation}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/family"
					component={CustomerDetailsTaskPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/loadingFamily"
					Component={GettingDetails}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/loadingHome"
					Component={LoadingHome}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/homePage"
					Component={HomePage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/addCustomer"
					Component={AddNewCustomerPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/consultations-today"
					Component={TodaysConsultationsPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/products"
					Component={BuyProductsPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/admin"
					Component={AdminTaskPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/care-upload"
					Component={CareDocUpload}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/sales"
					Component={SalesPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				{/* <PrivateRoute
					path="/ticketing"
					Component={TicketingPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/> */}
				<PrivateRoute
					path="/visits"
					Component={VisitsPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/claims"
					Component={ClaimsPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/doctors"
					Component={CreateDoctorPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/membership"
					Component={Membership}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/utilities"
					Component={UtilitiesPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>
				<PrivateRoute
					path="/customerLens"
					Component={CustomerLensPage}
					isCurrentClinicSet={!_.isEmpty(props.auth.currentClinicLocation)}
				/>

			</Switch>
		</Router>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		familyDetails: state.familyDetails,
		error: state.error,
		consultationDetails: state.consultationDetails,
	};
};

export default connect(mapStateToProps)(App);
